import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { ROUTES } from '/config/routes';
import { SpaceCardPlaceholder } from '/design-systems/Molecules/Placeholders/SpaceCardPlaceholder';
import { SpaceCard } from '/design-systems/Molecules/Cards/SpaceCard';
import { InviteModal } from '/design-systems/Molecules/Modals/InviteModals/InviteModal';
import { CampaignCardPlaceholder } from '/design-systems/Molecules/Placeholders/CampaignCardPlaceholder';
import { GalleryCardPlaceholder } from '/design-systems/Molecules/Placeholders/GalleryCardPlaceholder';
import { LoopingCarousel } from '/design-systems/Molecules/LoopingCarousel';
import { PromptCard } from '/design-systems/Molecules/Cards/PromptCard';
import { PromoBanner } from '/design-systems/Atoms/PromoBanner';
import { Typography } from '/design-systems/Atoms/Typography';
import { Button } from '/design-systems/Atoms/Button';
import { getPromptContributors } from '/services/joynApi/users/prompt-submission';

import common from '../styles/Common.module.css';
import { getInvitation } from '../services/joynApi/users/collaboration-invitation';

import { acceptInvitation } from '/services/joynApi/producers/collaboration-invitation';
import { useFeaturedAssets } from '/hooks/useFeaturedAssets';
import { useGalleries } from '/hooks/useGalleries';
import { useCampaigns } from '/hooks/useCampaigns';
import { useSpaces } from '/hooks/useSpaces';
import { useAuthRouterHelpers } from '/hooks/router';

import { getCoCreationPageRoute } from '/utils/co-creation';
import { mapProjectMeta } from '/utils/project';
import {
  getLogo,
  getCover,
  getAvatar,
  promptPlaceholderImg
} from '/utils/image';
import { useModalManager, MODALS } from '/context/ModalManager';
import { emptyArray } from '/utils/object';
import {
  COLLABORATABLE_TYPES,
  META_ENTITIES,
  META_TAGS
} from '/utils/constants';
import { promptCardImg } from '/utils/prompt';
import { FeaturedSection } from '/design-systems/Molecules/Section/FeaturedSection';

import { StoryCard } from '/design-systems/Molecules/Cards/StoryCard';
import { useFeatureFlags } from '/hooks/useFeatureFlags';
import { FeaturedHomeAsset } from '/design-systems/Organisms/FeaturedHomeAsset';
import { TRACKED_EVENTS, useAnalytics } from '/hooks/useAnalytics';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMegaphone,
  faCircleExclamation,
  faTowerBroadcast
} from '@fortawesome/pro-regular-svg-icons';
import { getGalleryCoverImage } from '/utils/gallery';

const NUM_SHOWCASES_TO_FETCH = 10;
const NUM_ITEMS_TO_SHOW = 3;

export default function HomePage() {
  const router = useRouter();
  const { navigateToLogin, navigateToSignup } = useAuthRouterHelpers();
  const {
    // @prettier-ignore
    isFeaturedShowcaseEnabled,
    isFeaturedHomeAssetEnabled,
    isArtPlusFlowEnabled,
    isListingPromoEnabled
  } = useFeatureFlags();

  const { featuredAssets } = useFeaturedAssets();
  // setting enabled false to prevent fetching spaces on initial load for now
  // will be reinstated when we have the space search feature in home page
  const { spaces, isLoading } = useSpaces({
    size: NUM_ITEMS_TO_SHOW,
    enabled: true
  });
  const { campaigns, isLoadingCampaigns } = useCampaigns({
    size: NUM_ITEMS_TO_SHOW,
    enabled: true
  });
  const { galleries: stories, isLoading: isFetchingStories } = useGalleries({
    key: 'story',
    size: NUM_ITEMS_TO_SHOW,
    enabled: true,
    filters: {
      galleryType: META_ENTITIES.STORY
    }
  });
  const { galleries: showcases, isLoading: isFetchingShowcases } = useGalleries(
    {
      key: 'showcase',
      size: NUM_SHOWCASES_TO_FETCH,
      enabled: true,
      filters: {
        galleryType: META_ENTITIES.SHOWCASE
      }
    }
  );

  const [featuredIndex, setFeaturedIndex] = useState(0);

  const { showModal, closeModal } = useModalManager();
  const { assetId } = router?.query || {};

  // for producer invites
  const currentUser = useSelector((state) => state.user.current);
  const { validationCode: paramValidationCode } = router.query;
  const [validationCode, setValidationCode] = useState(null);
  const [accepting, setAccepting] = useState(false);
  const [invitedProject, setInvitedProject] = useState(null);
  const [invitedPrompt, setInvitedPrompt] = useState(null);
  const [invitedShowcase, setInvitedShowcase] = useState(null);
  const [showInvitationViewModal, setShowInvitationViewModal] = useState(false);

  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const promptIds = campaigns.map((c) => c.id);
      if (emptyArray(promptIds)) return;

      const contributorsResponse = await getPromptContributors({ promptIds });
      setContributors([...contributors, ...contributorsResponse.data.data]);
    };

    fetchData();
  }, [campaigns]);

  const onAcceptInvitation = async () => {
    try {
      await acceptInvitation(validationCode);
      if (invitedProject)
        router.push(
          ROUTES.space.viewBySlug(invitedProject.title, invitedProject.slug)
        );
      if (invitedPrompt)
        router.push(
          ROUTES.contest.viewBySlug(invitedPrompt.title, invitedPrompt.slug)
        );
      if (invitedShowcase) {
        router.push(ROUTES.showcase.view(invitedShowcase.id));
      }
    } catch (error) {
      console.error('Accept Invitation Error: ', error);
    }

    setAccepting(false);
    setShowInvitationViewModal(false);
  };

  const handleAcceptInvitation = async () => {
    setAccepting(true);
    if (!currentUser || !Object.keys(currentUser).length) {
      if (invitedProject?.isUserExists || invitedPrompt?.isUserExists) {
        navigateToLogin({ isForce: true });
      } else {
        navigateToSignup({ returnUrl: router.query?.returnUrl, isForce: true });
      }
    } else {
      onAcceptInvitation();
    }
  };

  const isFeaturedAsset = useCallback(
    (assetId) => featuredAssets?.map((a) => a.id.toString()).includes(assetId),
    [featuredAssets]
  );

  useEffect(() => {
    if (!featuredAssets) return;

    const featuredTimer = setTimeout(() => {
      setFeaturedIndex((featuredIndex + 1) % featuredAssets.length);
    }, 5000);

    return () => {
      clearTimeout(featuredTimer);
    };
  }, [featuredAssets, featuredIndex]);

  useEffect(() => {
    // if there is no params in the url, then close the modal
    if (!assetId) {
      closeModal(MODALS.PREVIEW_MODAL);
      return;
    }

    // open modal for featured assets
    if (isFeaturedAsset(assetId)) {
      showModal({
        modalId: MODALS.PREVIEW_MODAL,
        props: {
          queryParam: assetId,
          isAsset: true,
          withPagination: false,
          onClose: () => {
            const { assetId: _, ...queryParam } = router.query;
            router.push(
              {
                pathname: router.pathname,
                query: queryParam
              },
              undefined,
              { scroll: false }
            );
            closeModal();
          }
        }
      });
    }
  }, [assetId]);

  useAnalytics({
    event: TRACKED_EVENTS.HOME_VIEW,
    values: {},
    enabled: true
  });

  const renderOpenCallCards = () => {
    if (isLoadingCampaigns) {
      return (
        <div className="flex grid grid-cols-1 items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          <CampaignCardPlaceholder />
          <CampaignCardPlaceholder />
          <CampaignCardPlaceholder />
        </div>
      );
    }

    return (
      <div className="campaigns-section flex grid grid-cols-1 items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {campaigns?.slice(0, 3)?.map((campaign) => {
          const contributorsData =
            !emptyArray(contributors) &&
            contributors.find((c) => c?.promptId == campaign.id);
          return (
            <PromptCard
              contributors={contributorsData?.avatarUrls?.map(
                (avatarUrl, i) => ({
                  id: i,
                  avatar: avatarUrl
                })
              )}
              contributorsCount={contributorsData?.numberOfUsers}
              description={campaign.description}
              editable={true}
              endsIn={campaign.endDate}
              id={campaign.slug}
              key={`prompt-${campaign.slug}`}
              link={getCoCreationPageRoute({
                title: campaign.title,
                slug: campaign.slug,
                promptableType: campaign.promptableType,
                action: 'view'
              })}
              projectsMeta={campaign?.Projects?.map((project) =>
                mapProjectMeta(project || {})
              )}
              prompt={{
                ...(campaign ?? {})
              }}
              rewards={campaign.PromptRewards}
              title={campaign.title}
              onProjectClick={(project) => {
                router.push(
                  ROUTES.space.viewBySlug(project.title, project.slug)
                );
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderGalleryCards = ({ loading, galleries = [] }) => {
    if (loading) {
      return (
        <div className="flex w-full items-center gap-6">
          <GalleryCardPlaceholder />
          <GalleryCardPlaceholder />
          <GalleryCardPlaceholder />
        </div>
      );
    }

    return (
      <div className="galleries-section grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {galleries?.slice(0, 3)?.map((gallery) => (
          <StoryCard key={gallery.id} gallery={gallery} />
        ))}
      </div>
    );
  };

  const renderSpaceCards = () => {
    return (
      <div className="flex flex-col gap-6">
        {spaces?.map((space) => {
          const spaceUrl = ROUTES.space.viewBySlug(space.title, space.slug);

          return (
            <SpaceCard
              assetCount={space.assetCount}
              assets={space.Assets}
              coverUrl={getCover(space)}
              key={`space-${space.id}`}
              logoUrl={getLogo(space)}
              queryParam={assetId}
              slug={space.slug}
              spaceId={space.id}
              spaceUrl={spaceUrl}
              title={space.title}
            />
          );
        })}

        {isLoading && <SpaceCardPlaceholder />}
      </div>
    );
  };

  // for accepting producer invites
  useEffect(() => {
    const getCollaboratable = async () => {
      try {
        const {
          data: {
            data: {
              Project,
              collaboratableType,
              Prompt,
              inviter,
              acceptedAt,
              isUserExists,
              Gallery
            }
          }
        } = await getInvitation(validationCode);

        if (collaboratableType === COLLABORATABLE_TYPES.PROJECT) {
          setInvitedProject({
            id: Project.id,
            title: Project.title,
            slug: Project.slug,
            avatar: Project.Files.find(({ type }) => type == 'logo'),
            background: Project.Files.find(({ type }) => type == 'cover'),
            users: [
              {
                id: inviter.id,
                name: inviter.name,
                username: inviter.username,
                avatar: getAvatar(inviter)
              }
            ],
            expired: !!acceptedAt,
            isUserExists
          });
        }
        if (collaboratableType === COLLABORATABLE_TYPES.PROMPT) {
          const coverImg = promptCardImg(Prompt);
          setInvitedPrompt({
            id: Prompt.id,
            title: Prompt.title,
            slug: Prompt.slug,
            backgroundImgSrc: coverImg?.src,
            isBackgroundStaticFile: coverImg?.staticFile,
            users: Object.values(
              [
                inviter,
                ...Prompt.PromptCollaborators.map(({ User: user }) => user)
              ].reduce((acc, obj) => {
                acc[obj.id] = obj;
                return acc;
              }, {})
            ),
            isUserExists
          });
        }
        if (collaboratableType === COLLABORATABLE_TYPES.GALLERY) {
          const coverImg = getGalleryCoverImage(Gallery) || {
            src: promptPlaceholderImg(Gallery),
            staticFile: true,
            placeholder: true
          };
          setInvitedShowcase({
            id: Gallery.id,
            title: Gallery.title,
            slug: Gallery.slug,
            backgroundImgSrc: coverImg?.src,
            isBackgroundStaticFile: coverImg?.staticFile,
            users: [
              {
                id: inviter.id,
                name: inviter.name,
                username: inviter.username,
                avatar: getAvatar(inviter)
              }
            ],
            expired: !!acceptedAt,
            isUserExists
          });
        }

        setShowInvitationViewModal(true);
      } catch (error) {
        console.error('Getting Project Error:', error);
      }
    };

    if (paramValidationCode) {
      setValidationCode(paramValidationCode);
    }
    validationCode && getCollaboratable();
  }, [paramValidationCode, validationCode]);

  return (
    <>
      <PromoBanner
        title={
          <div className="flex flex-col gap-2">
            <Typography variant="medium" className="!text-black">
              We will be sunsetting the Joyn platform on <em>Feb 1, 2025</em>.
              Click to read our announcement post.
            </Typography>

            <Typography colorVariant="secondary">
              Note: the @joynxyz X account is still compromised, please do not
              interact with it
            </Typography>
          </div>
        }
        bodyClassName="!py-6 !bg-neutral-100 !hover:bg-neutral-200 text-black"
        borderClassName="bg-monochrome-gradient"
        contentClassName="gap-4"
        icon={
          <FontAwesomeIcon
            icon={faMegaphone}
            className="h-6 w-6 text-neutral-500"
          />
        }
        onClick={() =>
          window.open(
            'https://chestr-labs.notion.site/Update-to-the-Joyn-community-16d76823b4ce809e84b8db442e105d64?pvs=73',
            '_blank'
          )
        }
      />

      {false && (
        <PromoBanner
          title={
            <Typography variant="medium">
              The @Joynxyz X account has been hacked. Please do not click on
              links from that account. Meanwhile, this site (joyn.xyz) is safe
              and functioning normally.
            </Typography>
          }
          icon={
            <FontAwesomeIcon icon={faCircleExclamation} className="h-5 w-5" />
          }
        />
      )}

      {false && isListingPromoEnabled ? (
        <PromoBanner
          title={
            <Typography variant="medium">
              Introducing{' '}
              <span className="font-semibold">open curator fee</span>. List
              once, curated anywhere.
            </Typography>
          }
          icon={<FontAwesomeIcon icon={faTowerBroadcast} className="h-5 w-5" />}
          onClick={() => router.push(ROUTES.asset.newListing())}
        />
      ) : (
        <></>
      )}

      {/* Display featured home asset */}
      {isFeaturedHomeAssetEnabled && <FeaturedHomeAsset />}

      {/* more prominent display of featured Showcases */}
      {isFeaturedShowcaseEnabled && (
        <div className="mx-auto mt-6 flex w-full max-w-full flex-col gap-4">
          <div className="w-full px-6">
            <div className="mx-auto max-w-[1280px]">
              <Typography heading variant="h5" weight="semibold">
                Featured showcases
              </Typography>
            </div>
          </div>
          <LoopingCarousel
            galleries={showcases}
            isLoading={isFetchingShowcases}
          />
          <Button
            className="mx-auto w-fit items-center gap-x-1 px-3"
            color="primary"
            variant="secondary"
            onClick={() => router.push(ROUTES.explore.showcases)}
          >
            <Typography variant="medium" weight="medium">
              View all
            </Typography>
          </Button>
        </div>
      )}
      <div className={common.content}>
        <div className="mx-auto flex max-w-[1280px] flex-col gap-y-12">
          {/* Open calls section */}
          <FeaturedSection
            href={ROUTES.explore.openCalls}
            id="featured-open-calls"
            title="Featured open calls"
          >
            {renderOpenCallCards()}
          </FeaturedSection>

          {/* Showcases section — default version */}
          {isFeaturedShowcaseEnabled || (
            <FeaturedSection
              href={ROUTES.explore.showcases}
              id="featured-show-cases"
              title="Featured showcases"
            >
              {renderGalleryCards({
                galleries: showcases,
                loading: isFetchingShowcases
              })}
            </FeaturedSection>
          )}

          {/* Galleries section */}
          <FeaturedSection
            href={ROUTES.explore.galleries}
            id="featured-stories"
            title="Featured stories"
          >
            {renderGalleryCards({
              galleries: stories,
              loading: isFetchingStories
            })}
          </FeaturedSection>

          {/* Spaces section */}
          <FeaturedSection
            href={ROUTES.explore.spaces}
            id="featured-spaces"
            title="Featured spaces"
          >
            {renderSpaceCards()}
          </FeaturedSection>
        </div>

        {invitedProject && (
          <InviteModal
            avatar={invitedProject.avatar?.fileUrl}
            background={invitedProject.background?.fileUrl}
            expired={invitedProject.expired}
            loading={accepting}
            open={showInvitationViewModal}
            title={invitedProject.title}
            users={invitedProject.users}
            onAccept={handleAcceptInvitation}
            onClose={() => setShowInvitationViewModal(false)}
          />
        )}
        {invitedPrompt && (
          <InviteModal
            background={invitedPrompt.backgroundImgSrc}
            expired={invitedPrompt.expired}
            isBackgroundStaticFile
            loading={accepting}
            modalTitle={'Join Open Call'}
            open={showInvitationViewModal}
            title={invitedPrompt.title}
            users={invitedPrompt.users}
            onAccept={handleAcceptInvitation}
            onClose={() => setShowInvitationViewModal(false)}
          />
        )}
        {invitedShowcase && (
          <InviteModal
            background={invitedShowcase.backgroundImgSrc}
            expired={invitedShowcase.expired}
            isBackgroundStaticFile={invitedShowcase.isBackgroundStaticFile}
            loading={accepting}
            modalTitle={'Join Showcase'}
            open={showInvitationViewModal}
            title={invitedShowcase.title}
            users={invitedShowcase.users}
            onAccept={handleAcceptInvitation}
            onClose={() => setShowInvitationViewModal(false)}
          />
        )}
      </div>
    </>
  );
}

export function getServerSideProps() {
  return {
    props: {
      metadata: META_TAGS.DEFAULT
    }
  };
}
