import React from 'react';

import { Typography } from '/design-systems/Atoms/Typography';
import { ROUTES } from '/config/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { mergeClassNames } from '/utils/string';

export const PromoBanner = ({
  title,
  icon,
  onClick,
  bodyClassName,
  contentClassName,
  borderClassName = 'bg-rainbow-gradient'
}) => {
  return (
    <div className="mt-2 flex flex-col">
      {/* top gradient border */}
      <div className={mergeClassNames('h-[2px] w-full', borderClassName)}></div>

      {/* banner body */}
      <div
        className={mergeClassNames(
          'cursor-pointer bg-neutral-600 px-6 py-3 text-white hover:bg-neutral-700 xl:px-0',
          bodyClassName
        )}
        onClick={onClick}
      >
        <div
          className={mergeClassNames(
            'mx-auto flex w-full max-w-[1280px] items-center gap-2',
            contentClassName
          )}
        >
          {icon || <FontAwesomeIcon icon={faSparkles} className="h-5 w-5" />}

          {title}
        </div>
      </div>

      {/* bottom gradient border */}
      <div
        className={mergeClassNames(
          'h-[2px] w-full rotate-180',
          borderClassName
        )}
      ></div>
    </div>
  );
};
